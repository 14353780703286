export default {
    computed: {
        filteredMenu() {
            const menu = [];

            this.user.nav_menu.forEach((menuItem) => {
                if (this.allowDivision(menuItem)) {
                    const children = [];

                    menuItem.children.forEach((child) => {
                        if (this.allowDivision(child)) {
                            children.push(child)
                        }
                    })

                    const itemCopy = { ...menuItem, children };
                    menu.push(this.normalizeItemUrl(itemCopy));
                }
            });

            return menu;
        },
    },
    methods: {
        allowDivision(item) {
            return item.divisions === 'Any' || this.hasDivision(item.divisions);
        },
        normalizeItemUrl(item) {
            if (item.to?.startsWith('http')) {
                item.href = item.to;
                item.to = null;
            }

            if (item.href && item.href.startsWith('/')) {
                item.to = item.href;
                item.href = null;
            }

            if (item.children) {
                item.children.forEach((child) => {
                    this.normalizeItemUrl(child);
                });
            }

            return item;
        },
    },
};
