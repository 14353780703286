<template>
    <v-form ref="form" :disabled="loading">
        <v-row wrap>
            <v-col cols="12">
                <Dropzone
					v-if="!onlyMeta"
					v-model="file"
					type="single"
					:dropzoneMessage="dropzoneMessage"
					:rules="$props.doNotRequireFile ? [] : [(v) => !!v || 'File is required']"
					:disabled="loading"
				>
                    <template #message>{{ dropzoneDescriptionMessage }}</template>
                </Dropzone>
            </v-col>
            <v-col cols="12">
                <v-text-field v-model="newDoc.DocTitle" label="Title*" :rules="[(v) => !!v || 'File name is required']" />
            </v-col>
            <v-col cols="12">
                <v-text-field v-model="newDoc.DocDesc" label="Description*" :rules="[(v) => !!v || 'File description is required']" />
            </v-col>
			<template v-if="isMarketingResource">
				<v-col cols="12" sm="6">
					<v-select v-model="newDoc.Audience" label="Audience*" :items="['Agent', 'Customer']" :rules="[(v) => !!v || 'Audience is required']" />
				</v-col>
				<v-col cols="12" sm="6">
					<DocumentGroupSelect v-model="newDoc.SubCategory" :items="groups" label="Group*" :loading="itemsLoading" :rules="[(v) => (!!v && v.length > 0) || 'File group is required']" />
				</v-col>
				<v-col cols="12">
					<DocumentTagsSelect v-model="newDoc.DocumentTags" :items="subjects" :loading="itemsLoading" />
				</v-col>
			</template>
            <v-col cols="12" sm="6">
                <v-checkbox v-model="newDoc.SecureDocumentInd" label="Secure Document"></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
                <v-checkbox v-model="newDoc.SearchableInd" label="Searchable Document"></v-checkbox>
            </v-col>
			<template v-if="!isMarketingResource">
				<v-col cols="12" sm="6">
					<QDivisionSelect v-model="newDoc.Division" label="Division*" :with-all="true" />
				</v-col>
				<v-col cols="12" sm="6">
					<v-select v-model="newDoc.CategoryName" label="Category" item-text="category" item-value="category" :items="available_categories"></v-select>
				</v-col>
			</template>
            <v-col cols="12" class="d-flex">
                <slot name="left-to-save" />
                <v-spacer></v-spacer>
                <v-btn
					color="primary"
					@click="$refs.form.validate() && $emit('submit', { file, meta: newDoc })"
					:disabled="loading"
				>
					<v-progress-circular v-if="loading" class="mr-2" indeterminate size="24"></v-progress-circular>
					Save Changes
				</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import Category from '@/store/Models/Category'
import { acceptedFiles } from './acceptedFiles.ts'
import QFileUpload from '../utils/QFileUpload.vue'
import QDivisionSelect from '@/components/utils/QDivisionSelect.vue'
import Dropzone from './Dropzone.vue'
import DocumentTagsSelect from './DocumentTagsSelect.vue'
import DocumentGroupSelect from './DocumentGroupSelect.vue'
import QuilityAPI from '@/store/API/QuilityAPI'

export default {
    components: {
		QFileUpload,
		Dropzone,
		DocumentTagsSelect,
		DocumentGroupSelect,
		QDivisionSelect,
	},

    props: {
        doc: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        /** Show only meta data fields. Disallow user to load new file */
        onlyMeta: {
            type: Boolean,
            default: false,
        },
        doNotRequireFile: { 
            type: Boolean,
            default: false,
        },
    },

    data () {
        return { 
            acceptedFiles,
            file: null,
            newDoc: {},
			items: {},
			itemsLoading: false,
        }
    },

	created () {
		this.loadData()
	},

    computed: {
        isMarketingResource () {
            return this.newDoc.CategoryName === 'Marketing Resource Library'
        },
        available_categories () {
            const categories = Category.query().where('zendesk_category_id', 0).orderBy('category', 'asc').get()
            categories.unshift({ category: '-All-' })
            return categories
        },
		dropzoneMessage () {
			return this.newDoc.ID ? 'Click here or drop a file to upload a new version of the document' : undefined
		},
		dropzoneDescriptionMessage () {
			const maxFileSize = process.env.NODE_ENV === 'production' ? '50Mb' : '1Mb'
			return `Accepts: Image, PDF, Document or Video files. The maximum file size is ${maxFileSize}.`
		},
		groups () {
			return Object.keys(this.items)
		},
		subjects () {
			if (!this.groups.length) { return [] }
			const items = this.groups.map(group => this.items[group]).flat()
			const uniqueItems = [...new Set(items)].sort()
			const withoutDocumentTagsIndex = uniqueItems.indexOf('-empty-')
			if (withoutDocumentTagsIndex !== -1) {
				uniqueItems.splice(withoutDocumentTagsIndex, 1)
			}
			return uniqueItems
		},
    },

    watch: {
        doc: {
            handler (newDoc) {
                if (newDoc) {
                    this.newDoc = { ...newDoc }
                } else {
                    this.newDoc = {
                        DocTitle: '',
                        DocDesc: '',
						Division: '',
						CategoryName: this.available_categories[0].category,
                        SearchableInd: false,
                        SecureDocumentInd: false,
                    }
                }
            },
            immediate: true,
        },
        file: {
            handler (newFile) {
                if (newFile) {
                    if (this.newDoc.DocTitle !== '') { return }
                    this.newDoc.DocTitle = newFile.name
                }
            },
            immediate: true,
        },
    },

	methods: {
		async loadData () {
			if (!this.isMarketingResource) { return }
			this.itemsLoading = true
			this.items = {}
			const response = await QuilityAPI.getMarketingResourceLibrarySubjects()
			if (response.error) {
				this.showError('Could not load groups and subjects', response.msg)
			} else {
				this.items = response.data
			}
			this.itemsLoading = false
		},
	},
}
</script>
