export default {
    /**
     *
     * hits the endpoint /api/private/new_policies/pending/agent/{agent}
     * returns the pending policy data for the agent whose
     * agent code is {agent}
     *
     * @param {*} agent_code
     * @param {*} filters
     * @param {*} options
     */
    getAgentPendingPolicies(agent_code, filters, options) {
        var params = {
            ...options,
            ...filters
        }
        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getAgentPendingPolicies");
            return this.getRequest(
                '/api/private/new_policies/pending/agent/' + agent_code, //the end point for this resource.
                params, //filters, pagination and what not handles by the datatable
                "getAgentPendingPolicies" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    },

    /**
     *
     * hits the endpoint /api/private/policypending/baseshop/{agent}
     * returns the pending policy data for all of the agents in the
     * the AO's baseshop where {agent} is the agent code of the AO.
     * Does not return the AO's pending policies.
     *
     * @param {*} agent_code
     * @param {*} filters
     * @param {*} options
     */
    getBaseShopPolicies(agent_code, filters, options) {
        var params = {
            ...options,
            ...filters
        }
        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getBaseShopPolicies");
            return this.getRequest(
                '/api/private/policypending/baseshop/' + agent_code, //the end point for this resource.
                params, //filters, pagination and what not handles by the datatable
                "getBaseShopPolicies" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    },

    /**
     *
     * hits the endpoint /api/private/policypending/statusreasoncount/{agent}
     * returns list of the distinct status reasons for all of the agents
     * pending polices where the agent code is {agent}
     *
     * @param {*} agent_code
     * @param {*} filters
     * @param {*} options
     */
    getAgentUniqueStatusCount(agent_code) {
        var params = {}

        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getAgentUniqueStatusCount")
        return this.getRequest(
            '/api/private/policypending/statusreasoncount/' + agent_code, //the end point for this resource.
            params, //filters, pagination and what not handles by the datatable
            "getAgentUniqueStatusCount" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    },

    /**
     *
     * hits the endpoint /api/private/policypending/statusreasoncountbaseshop/{agent}
     * returns list of the distinct status reasons for all the pending polices in the
     * AO's baseshop where the agent code is {agent}
     *
     * @param {*} agent_code
     * @param {*} filters
     * @param {*} options
     */
    getBaseShopUniqueStatusCount(agent_code){
        var params = {}

        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getAgentUniqueStatusCount")
        return this.getRequest(
            '/api/private/policypending/statusreasoncountbaseshop/' + agent_code, //the end point for this resource.
            params, //filters, pagination and what not handles by the datatable
            "getAgentUniqueStatusCount" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    },

    getAgentUniquePolicyStatusCount(agent_code){
        var params = {}

        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getAgentUniquePolicyStatusCount")
        return this.getRequest(
            '/api/private/policypending/policystatuscount/' + agent_code, //the end point for this resource.
            params, //filters, pagination and what not handles by the datatable
            "getAgentUniquePolicyStatusCount" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    },

    getAgentUniquePolicyStatusBaseShopCount(agent_code){
        var params = {}

        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getAgentUniquePolicyStatusBaseShopCount")
        return this.getRequest(
            '/api/private/policypending/baseshoppolicystatuscount/' + agent_code, //the end point for this resource.
            params, //filters, pagination and what not handles by the datatable
            "getAgentUniquePolicyStatusBaseShopCount" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    },

    getAgentNonPolicySupplyingCarriers(agent_code){
        var params = {}

        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getAgentNonPolicySupplyingCarriers")
        return this.getRequest(
            '/api/private/policypending/nonpendingdatasupplyingcarriers/' + agent_code, //the end point for this resource.
            params, //filters, pagination and what not handles by the datatable
            "getAgentNonPolicySupplyingCarriers" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    },

    getAgentPolicyCountSinceLastVisit(agent_code) {

        //unique queu to cancel previous requests for this resource.
        this.cancelQRequest("getAgentPolicyCountSinceLastVisit");
        return this.getRequest(
            '/api/private/policypendingcount/agent/' + agent_code, //the end point for this resource.
            null, //filters, pagination and what not handles by the datatable
            "getAgentPolicyCountSinceLastVisit" //the unique queu key to track requests to this resource.
        ).then(function(json) {
            return json;
        })
    },
}
