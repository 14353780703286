<template>
    <div class="editable-table-cms-block">
		<VAlert type="error" v-if="rows.length > 200 || columns.length > 200">
			Data might be not saved or saved incorrectly. 
			There are too much rows or columns. Please, make this block smaller.
		</VAlert>

		<div class="d-flex py-2" style="gap: 2rem; align-items: center;">
			<TextEditorMenuBar class="editable-table-cms-block__menu-bar" :editor="editor" />
			<VSpacer />
			<VBtn @click="doShowDelimiterDialog = true">Import from CSV</VBtn>
		</div>
		<div class="editable-table__wrapper">
			<table class="editable-table">
				<thead>
					<tr>
						<th class="editable-table__drag-col"></th>
						<th v-for="(column) in columns" :key="column.value" :style="{ width: column.width ?? `max(${normalizeWidth(column.width)}, 60px)` }" data-resizable="true">
							<div class="d-flex" style="gap: 0.5rem; align-items: center;" :key="column.value">
								<input v-model="column.text" style="width: 100%; min-width: 0" placeholder="Column name" />
								<VSpacer />
								<VBtn x-small @click="() => { columnToEdit = column; doShowEditColumnDialog = true}" icon>
									<VIcon style="font-size: 0.5rem;">fas fa-pen</VIcon>
								</VBtn>
								<VBtn x-small @click="columns = columns.filter((name) => name.value !== column.value)" color="error" icon>
									<VIcon style="font-size: 0.5rem;">fas fa-trash</VIcon>
								</VBtn>
							</div>
						</th>
						<th style="width: 2rem;">
							<VBtn x-small @click="columns.push(makeColumn())" color="primary" icon>
								<VIcon style="font-size: 0.5rem;">fas fa-plus</VIcon>
							</VBtn>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="row in paginatedRows" :key="row.key">
						<td draggable="true" @dragstart="onDragStart" @dragover="onDragover" @dragend="onDragEnd" class="editable-table__drag-col">
							<VIcon small>fas fa-bars</VIcon>
						</td>
						<td 
							v-for="(column) in columns" 
							:key="column.value" 
							:style="{ width: column.width }"
						>
							<TextEditor v-model="row[column.value]" :key="column.value" :hideMenuBar="true" @click="editor = $event" />
						</td>
						<td style="width: 2rem; text-align: center;">
							<VBtn x-small @click="rows = rows.filter((r) => row !== r)" color="error" icon>
								<VIcon style="font-size: 0.5rem;">fas fa-trash</VIcon>
							</VBtn>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="d-flex w-full">
			<VBtn @click="addNewRow" color="primary" style="width: 100%">
				<VIcon style="font-size: 0.5rem; margin-right: 0.5rem">fas fa-plus</VIcon>
				Add row
			</VBtn>
		</div>

		<div class="d-flex mt-4" style="justify-content: space-evenly; align-items: center">
			<VPagination v-model="curPage" :length="Math.floor((rows.length - 1) / perPage) + 1" :total-visible="6" style="justify-content: flex-end" />
			<VSelect
				label="Per page"
				v-model="perPage"
				:items="[
					10, 20, 50, 100,{ value: 9999999, text: 'All' },
				]"
				item-value="value"
				item-text="text"
			/>
		</div>

		<VDialog v-model="doShowDelimiterDialog" width="600px">
			<VCard>
				<VCardTitle>
					<h2>Import data from CSV</h2>
				</VCardTitle>
				<VCardText>
					<div class="file-dropzone">
						<input
							class="file-dropzone__input"
							type="file"
							accept="text/csv"
							:multiple="false"
							@change="onFileUpload"
						/>
						{{ csvFile ? csvFile.name : 'Click or drop csv file here' }}
						<p class="file-dropzone__hint">{{ !csvFile ? '' : 'Click or drop csv file here' }}</p>
					</div>
					<v-expansion-panels v-if="csvFileContent" flat>
						<v-expansion-panel>
							<v-expansion-panel-header>
								Additional settings
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<VTextField v-model="delimiter" label="Delimiter" :messages="`Common delimiters is ',' or ';'. Most likely your delimiter is '${suggestedDelimiter}'`" />
							</v-expansion-panel-content>
						</v-expansion-panel>						
					</v-expansion-panels>
				</VCardText>
				<VCardText align="right">
					<VBtn @click="doShowDelimiterDialog = false" :disabled="!csvFileContent">OK</VBtn>
				</VCardText>
			</VCard>
		</VDialog>

		<VDialog v-if="columnToEdit" v-model="doShowEditColumnDialog" width="600px">
			<VCard>
				<VCardTitle>
					<h3>Edit column "{{ this.columnToEdit.text }}"</h3>
				</VCardTitle>
				<VCardText>
					<VTextField v-model="columnToEdit.text" label="Column name" />
					<VTextField v-model="columnToEdit.width" label="Column width" hint="Can be value in pixels or percent. e.g. 120px or 50%" />
				</VCardText>
				<VCardText align="right">
					<VBtn @click="doShowEditColumnDialog = false">OK</VBtn>
				</VCardText>
			</VCard>
		</VDialog>
	</div>
</template>

<script>
import QFileUpload from '@/components/utils/QFileUpload.vue';
import TextEditor from '../TextEditor.vue'
import TextEditorMenuBar from '../TextEditorMenuBar.vue';
import { CSVToArray, findDelimiter } from './parse-csv.js'
import { VBtn, VIcon, VTextField } from 'vuetify/lib';
import { EditorMenuBar } from 'tiptap'
import { resizableGrid } from "./make-table-resizable"

export default {
    props: ['value'],
	components: {
    TextEditor,
    QFileUpload,
    VBtn,
    VTextField,
    EditorMenuBar,
    TextEditorMenuBar,
    VIcon
},
    data: function() {
        return {
			columns: [],
			rows: [],
			doShowDelimiterDialog: false,
			delimiter: ';',
			suggestedDelimiter: '',
			csvFile: null,
			csvFileContent: '',
			editor: null,

			doShowEditColumnDialog: false,
			columnToEdit: null,
			draggedRow: null,
			// Prevent flickering when dragging
			canBeDragged: true,
			draggedFrom: -1,
			curPage: 1,
			perPage: 10,
        }
    },
	computed: {
		tableData() {
			return {
				columns: this.columns,
				rows: this.rows,
			}
		},
		paginatedRows() {
			const start = (this.curPage - 1) * this.perPage
			const end = start + this.perPage
			return this.rows.slice(start, end)
		}
	},
    created: function() {
        this.reset();
    },
    methods: {
		normalizeWidth(width) {
			if (!width) { return }

			if (width.endsWith('%')) {
				return width
			}

			if (width.endsWith('px')) {
				return width
			}

			return width + 'px'
		},
		addRowsKeys(rows) {
			return rows.map((row) => {
				return {
					...row,
					key: Math.random().toString(36).substring(7)
				}
			})
		},
		removeRowsKeys(rows) {
			return rows.map((row) => {
				const { key, ...rest } = row
				return rest
			})
		},
		makeRow() {
			const row = {}
			this.columns.forEach((column) => {
				row[column.value] = ''
			})
			return row
		},
		makeColumn() {
			return {
				value: `column${this.columns.length}`,
				text: '',
				width: '80px'
			}
		},
		addNewRow() {
			this.rows.push(this.makeRow())
			this.curPage = Math.floor((this.rows.length - 1) / this.perPage) + 1
		},
		// Drag'n'Drop
		swapRowsDebounce(index1, index2) {
			if (this.timeout) {
				clearTimeout(this.timeout);
			}
			const temp = this.rows[index1];
			this.rows[index1] = this.rows[index2];
			this.rows[index2] = temp;
			this.rows = [...this.rows];
		},
		findTr(el) {
			if (!el) { return null }

			return el.tagName === 'TR' ? el : this.findTr(el.parentNode)
		},
		onDragStart(event){  
			this.draggedRow = this.findTr(event.target); 
		},
		onDragover(e){
			e.preventDefault();

			const tr = this.findTr(e.target)
			
			let children = Array.from(tr.parentNode.children);

			const targetIndex = children.indexOf(tr);
			const draggedIndex = children.indexOf(this.draggedRow);

			if (targetIndex === draggedIndex) {
				return
			}

			if (!this.canBeDragged) { return }

			if (targetIndex === draggedIndex) {
				return
			}

			this.canBeDragged = false
			setTimeout(() => {
				this.canBeDragged = true
			}, 500)

			this.swapRowsDebounce(draggedIndex, targetIndex);
		},
		onDragEnd() {
			this.draggedRow = null
		},
		// CSV
		parseCSV(file) {
			const reader = new FileReader();

			reader.onload = (e) => {
				const text = e.target.result;
				const data = CSVToArray(text, this.delimiter);
				const columns = data[0]
				this.rows = this.addRowsKeys(data
					.slice(1)
					.map((row) => {
						return row.reduce((acc, value, index) => {
							if (columns[index].trim() === '') {
								return acc;
							}

							acc[columns[index]] = value;
							return acc;
						}, {});
					}))

				this.columns = columns
					.filter((column) => column.trim() !== '')
					.map((column) => {
						if (column.startsWith('"') && column.endsWith('"')) {
							return column.slice(1, -1)
						}

						return column
					})
					.map((name, index, arr) => ({
						value: name, text: name, sortable: false, width: 100 / arr.length + '%'
					}))
			};

			reader.readAsText(file);
		},
		onFileUpload(event) {
			this.csvFile = event.target.files[0]

			const reader = new FileReader();

			reader.onload = (e) => {
				this.csvFileContent = e.target.result;
				this.suggestedDelimiter = findDelimiter(this.csvFileContent)
				this.delimiter = this.suggestedDelimiter
			};

			reader.readAsText(this.csvFile);
		},
        reset: function() {
            if (typeof this.value.TableData === 'undefined') {
                this.counter = this.counter + 1
                this.$set(this.value, 'TableData', {
                    id: this.counter,
                    title: "",
					columns: [{
						value: 'column1',
						text: 'Column 1',
						sortable: false,
						width: '100%'
					}],
					rows: [{
						'column1': '',
					}]
                })
            }
            this.$emit('input', this.value)

			this.columns = this.value.TableData.columns
			this.rows = this.addRowsKeys(this.value.TableData.rows)
        },
		onDataUpdate() {
			this.$emit('input', {
				...this.value,
				TableData: {
					rows: this.removeRowsKeys(this.rows),
					columns: this.columns,
				}
			})
		},
		onResize(columnIndex, width) {
			const tableWidth = this.$el.querySelector('.editable-table').offsetWidth
			const offsetWidth = width.replace('px', '')
			const percentWidth = offsetWidth / tableWidth * 100
			const columns = this.columns
			if (!columns[columnIndex]) { return }
			columns[columnIndex].width = `${percentWidth}%`
			this.columns = [...columns]
		},
	},
    watch: {
		perPage() {
			this.curPage = 1
		},
		rows: {
			deep: true,
			handler() { this.onDataUpdate() },
		},
		columns: {
			deep: true,
			handler() { 
				this.onDataUpdate()
			},
		},
        'value.type': function() {
            this.$nextTick(this.reset())
        },
		doShowDelimiterDialog(doShow) {
			if (doShow) {
				this.csvFile = null
			} else {
				this.parseCSV(this.csvFile)
			}
		},
		tableData() {
			this.$nextTick(() => {
				resizableGrid(this.$el.querySelector('.editable-table'), this.onResize)
			})
		}
    }
}
</script>

<style lang="scss">
  .file-dropzone {
	position: relative;
	border: 2px dashed #ccc;
	border-radius: 5px;
	padding: 1rem;
	margin-bottom: 1rem;
	z-index: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 4rem;

	p {
		margin: 0 !important;
	}

	&__hint {
		opacity: 0.5;
	}

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 1;
	}
  }

  .editable-table {
	table {
		table-layout: fixed;
		max-width: 100%;
		width: 100%;
		overflow: hidden;
		border: none;
		border-collapse: collapse;

		td {
			height: 48px; // We need base height to get .editor 100% work
		}

		th {
			padding: 0.5rem 0.2rem;
		}
	}

	&__drag-col {
		text-align: center;
		width: 2rem;

		@media screen and (max-width: 1024px) {
			& {
				width: 0;
				overflow: hidden;
				position: absolute;
				display: none;
				top: 0;
				left: 0;
			}
		}
	}

	&__wrapper {
		overflow-x: auto;

		table {
			min-width: 1024px;
		}
	}
  }

  .editable-table {
	table {
		table-layout: fixed;
		max-width: 100%;
		width: 100%;
		overflow: hidden;
	}

	.v-data-table-header__icon {
		display: none !important;
	}

	td {
		width: min-content;
		white-space: nowrap;
		padding: 0 !important;
	}

	th, td {
		border: 1px solid #ccc;
	}

	th:last-child {
		width: 2rem;
		text-align: center;
		padding: 0;
	}

	th {
		background: rgba(0, 0, 0, 0.1);
		padding: 0.5rem 0.2rem;
	}

	.editor {
		height: 100%;

		p {
			margin: 0;
		}

		.editor__content, .ProseMirror {
			padding: 0.25rem !important;
			height: 100%;

			&:focus {
				outline: 1px solid rgb(97, 97, 208);
				outline-offset: 0.25rem;
			}
		}
	}
  }

  .editable-table-cms-block__menu-bar {
	button {
		box-shadow: none;
		height: 24px !important;
	}
  }
</style>
