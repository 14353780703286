<template>
    <v-toolbar flat class="mb-5 toolbar-fix" style="width:100%" ref="submenu">
        <div class="">
            <p class="q-title-bar-title text-no-wrap">{{ title }}</p>
            <p v-if="titlelink.label" v-for="(titlelink, key) in titlelink" :key="key" class="q-title-bar-link"><a @click="goTo(titlelink)" target="_blank"> {{titlelink.label}}</a></p>
        </div>
        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        <v-divider class="ml-5" vertical inset v-if="$vuetify.breakpoint.mdAndUp && buttons.length > 0"></v-divider>

        <div
            v-if="$vuetify.breakpoint.mdAndUp && visibleButtons.length > 0"
            class="d-inline-flex justify-start flex-wrap flex-grow-1"
            ref="buttons"
        >
            <span v-for="(button, key) in visibleButtons" :key="key">
                <v-menu offset-y v-if="button && button.children && button.children.length > 0">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" :id="button.id" text class="mr-2 ml-2 d-lg-flex" style="justify-content: flex-start; ">
                            <v-icon left v-if=" !isBase && button.anchor">fas fa-arrow-alt-circle-right</v-icon>
                            <v-icon left v-else>{{ button.icon }}</v-icon>
                            {{ button.label }}
                        </v-btn>
                    </template>
                    <v-list style="min-width:230px;">
                        <div v-for="(sub, key2) in button.children" :key="key2" class="ma-5 pointer d-flex" icon="fas fa-scroll" @click="goTo(sub)">{{ sub.label }}</div>
                    </v-list>
                </v-menu>
                <v-btn v-else-if="button" @click="goTo(button)" :id="button.id" text class="mr-2 ml-2 d-lg-flex" style="justify-content: flex-start; ">
                    <v-icon left v-if=" !isBase && button.anchor">fas fa-arrow-alt-circle-right</v-icon>
                    <v-icon left v-else>{{ button.icon }}</v-icon>
                    {{ button.label }}
                </v-btn>
            </span>
        </div>

        <!--
            <v-menu offset-y v-model="show_search_results">
                <template v-slot:activator="{ on }">
                    <v-text-field v-model="search_leads" label="Find Lead" dense single-line hide-details append-icon="mdi-magnify"></v-text-field>
                </template>
                <v-list>
                    <v-list-item v-for="(result, key) in search_results" :key="key" @click="loadLead(result)">
                        {{result.Applicant}} {{result.Address}}
                    </v-list-item>
                </v-list>
            </v-menu>
        -->
        <v-spacer></v-spacer>
        <v-menu v-if="submenu.length || buttonsInSubmenu.length" offset-y>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon>fas fa-ellipsis-v</v-icon>
                </v-btn>
            </template>
            <v-list style="min-width:230px;">
                <div v-if="buttonsInSubmenu.length">
                    <div
                        v-for="(button, key) in buttonsInSubmenu"
                        :key="key + 'button'"
                        :icon="buttons.icon"
                        text
                        class="ma-5 d-flex pointer d-sm-flex d-md-flex"
                        @click="goTo(button)"
                    >{{ button.label }}</div>
                </div>
                <div
                    v-for="(sub, key) in submenu"
                    :key="key"
                    class="ma-5 pointer d-flex"
                    icon="fas fa-scroll"
                    @click="goTo(sub)"
                >{{ sub.label }}</div>
            </v-list>
        </v-menu>
    </v-toolbar>
</template>

<script>
//table data + search
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        titlelink: {
            type: Array,
            default: function() { return [] }
        },
        buttons: {
            type: Array,
            default: function() { return [] }
        },
        icon: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        submenu: {
            type: Array,
            default: function() { return [] }
        },
        to: {
            type: String,
            default: null
        },
        baseUrl: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            visibleButtonsAmount: null,
        }
    },

    computed: {
        options() {
            return {
                duration: 300,
                easing: 'easeInOutCubic',
            }
        },
        isBase: function() {
            return this.$router.currentRoute.path == this.baseUrl;
        },
        visibleButtons() {
            return this.visibleButtonsAmount
                ? [...this.buttons].slice(0, this.visibleButtonsAmount)
                : this.buttons
        },
        buttonsInSubmenu() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return this.buttons
            }

            if (this.visibleButtonsAmount) {
                return [...this.buttons].slice(this.visibleButtonsAmount)
            }

            return []
        },
    },

    mounted() {
        this.reorganizeSubmenu()
    },

    methods: {
        goTo(target) {
            if (target.anchor) {
                if (!this.isBase) {
                    this.$router.push(this.baseUrl + target.anchor);
                } else {
                    this.$vuetify.goTo(target.anchor, { easing: 'easeInOutCubic', offset: 80 })
                }
            }
            if (target.to) {
                this.$router.push(target.to)
            }
            if (target.href) {
                window.open(target.href, '_blank');
            }
            if (target.link) {
                window.open(target.href, '_blank');
            }
            if (target.fun) {
                target.fun();
            }
        },
        reorganizeSubmenu() {
            requestAnimationFrame(() => {
                if (this.$refs.buttons && this.$refs.buttons.offsetHeight > this.$refs.submenu.$el.offsetHeight) {
                    const width = this.$refs.buttons.offsetWidth
                    const buttons = this.$refs.buttons.children

                    let index = 0
                    for(let line = 0; line < 2; line++) {
                        let totalWidth = 0

                        while (index < buttons.length) {
                            const button = buttons[index]
                            totalWidth += button.offsetWidth

                            if (totalWidth > width) {
                                break
                            }

                            index++
                        }
                    }
                    this.visibleButtonsAmount = index
                }
            })
        },
    },
}
</script>
